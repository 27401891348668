document.body.classList.remove('no-js');

document.querySelector('.nav').addEventListener('click', evt => {
  if(evt.target.tagName.toLowerCase() === 'a'){
    evt.preventDefault();
    const ele = document.querySelector(evt.target.getAttribute('href'));
    const header = document.querySelector('.header');
    let dist = ele.offsetTop - header.clientHeight - 32;
    window.scroll(0, dist);
  }
});

/*document.getElementById('mc-embedded-subscribe-form').addEventListener('submit', evt => {
  evt.preventDefault();
  const action = 'https://dicetoldtales.us4.list-manage.com/subscribe/post-json?u=9123f47a3db11f0e71e993e1b&id=52c5a77e97&c=?';
  const method = 'get';
  console.log('submit', action, method);
});*/
